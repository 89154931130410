<!--
 * @Author: dingguowei
 * @Date: 2023-06-13 15:10:28
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-12 15:38:22
-->
<!-- 用户领取任务详情 -->
<template>
  <div class="taskdetails">
    <el-card class="left">
      <p class="title">文章详情</p>
      <div class="body" v-html="taskData.description ? taskData.description : ''"></div>
    </el-card>
    <el-card class="right">
      <p class="title">任务详情</p>
      <div class="head">
        <p style="font-size: 0.9rem; font-weight: 600">{{ taskData.title }}</p>
        <p style="font-size: 0.7rem; color: #999999">
          截止日期:{{ taskData.endAt }}
        </p>
      </div>
      <div class="body">
        <el-tabs v-model="activeName">
          <el-tab-pane label="详细描述" name="详细描述" style=" position: relative">
            <div style="height: 100%; display: flex; flex-direction: column">
              <div class="card" v-for="(item, index) in taskData.salaryListVos" :key="index">
                <p style="font-size: 0.8rem; font-weight: 600"
                  v-if="index === 0 || index === taskData.salaryListVos.length">
                  {{ item.type === 0 ? "基本任务要求" : "附加任务要求" }}
                </p>
                <div class="cardbody">
                  互动数满足{{ item.totalNum }}奖励{{ item.salary }}元
                </div>
              </div>
              <div style="margin-top: auto; text-align: right">
                <el-button style="color: #fff" :style="{
                  backgroundColor:
                    userStatus !== null ? '#999999' : '#5874ef',
                }" size="mini" :disabled="userStatus !== null" @click="userSave">{{ statusText(userStatus)
}}</el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="任务提交" name="任务进度" v-if="userStatus === 'TOFINISH' || userStatus === 'REFUSED'">
            <user_submit></user_submit>
          </el-tab-pane>
          <el-tab-pane label="审批结果" name="审批结果" v-if="userStatus === 'FORBID' || userStatus === 'REFUSED'">
            {{ endorse }}
          </el-tab-pane>
          <el-tab-pane label="任务示例" name="任务示例">
            <div class="content">
              <span v-html="taskData.example" style="display:block;overflow: auto;"></span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="生成文案" name="生成文案" v-if="userStatus !== null">
            <div class="content" style="min-height: 100%;">
              <span style="flex:1;overflow: auto;min-height: 80%;"> {{ content.response }}</span>
              <div v-if="content.imgUrls" style="display:flex;flex-wrap: wrap;">
                <el-image v-for="item, index in content.imgUrls" :key="index" style="width: 100px; height: 100px;"
                  :src="item" :preview-src-list="content.imgUrls">
                </el-image>
              </div>

              <span @click="copymsg()" class="copy" :data-clipboard-text="content.response">复制</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>

<script>
import task_tabs_details from "@/components/task/task_tabs_details.vue";
import store from "@/store";
import user_submit from "@/components/user/user_submit.vue";
import { mapState } from "vuex";
import { gettaskInfo, usersave, getauditresult, getcontent } from "@/api/user";
import user_submitVue from "@/components/user/user_submit.vue";
import Clipboard from "clipboard";
export default {
  components: {
    user_submit,
    // task_tabs_details
  },
  data() {
    return {
      taskData: [],
      activeName: "详细描述",
      card_item: [
        {
          name: "互动数",
          fieId: "totalNum",
          showDivder: true,
        },
        {
          name: "点赞数",
          fieId: "likeNum",
          showDivder: true,
        },
        {
          name: "收藏数",
          fieId: "collectNum",
          showDivder: true,
        },
        {
          name: "评论数",
          fieId: "commentNum",
          showDivder: true,
        },
        {
          name: "转发数",
          fieId: "shareNum",
        },
      ],
      endorse: "",
      content: "",
    };
  },
  created() { },
  mounted() {
    this.$nextTick(() => {
      this.gettaskInfo();
    });
  },
  watch: {
    userStatus: {
      handler(newvalue) {
        if (newvalue === "FORBID" || newvalue === "REFUSED") {
          this.getauditresult();
        }
      },
      deep: true,
      immediate: true,
    },
    activeName: {
      handler(newvalue) {
        if (newvalue === "生成文案") {
          this.getcontent();
        }
      },
    },
  },
  computed: {
    ...mapState("task", ["taskInfo"]),
    userStatus() {
      return store.state.user.userCurTaskStatus;
    },
    statusText() {
      return function (val) {
        if (val === "TOJUDGE") {
          return "待审核";
        } else if (val === "CLOSED") {
          return "已关闭";
        } else if (val === "FINISHED") {
          return "已通过";
        } else if (val === "REFUSED") {
          return "被拒绝";
        } else if (val === "FORBID") {
          return "被拉黑";
        } else if (val === "TOFINISH") {
          return "进行中";
        } else {
          return "领取任务";
        }
      };
    },
  },
  methods: {
    async gettaskInfo() {
      await gettaskInfo({
        missionId: this.taskInfo.missionId,
      }).then((res) => {
        this.taskData = res.data;
      });
    },
    userSave() {
      usersave({
        missionId: this.taskInfo.missionId,
        missionPublisherAccount: this.taskInfo.publisherAccount,
        missionPublisherId: this.taskInfo.publisherId,
      }).then((res) => {
        if (res.data !== null) {
          store.commit("task/CHANGE_missionReceiveId", res.data.id);
          store.commit("user/CHANGE_userCurTaskStatus", "TOFINISH");
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    getauditresult() {
      getauditresult({
        missionReceiveId: this.taskInfo.missionReceiveId,
      }).then((res) => {
        this.endorse = res.data.endorse;
      });
    },
    getcontent() {
      getcontent({
        missionId: this.taskInfo.missionId,
      }).then((res) => {
        this.content = res.data;

        this.content.imgUrls = this.content.imgUrls.split(",");

      });
    },
    // 复制信息
    copymsg(msg) {
      let _this = this;
      let clipboard = new Clipboard(".copy");

      clipboard.on("success", function (e) {
        // _this.$message("复制成功");
        e.clearSelection();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.taskdetails {
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 0.8rem;
  }

  ::v-deep .el-card__body {
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
  }

  .left {
    flex: 1;
    margin-right: 10px;

    .body {
      flex: 1;
      box-sizing: border-box;
      padding: 10px 0 0 0;
      overflow: auto;
    }
  }

  .right {
    width: 26rem;

    ::v-deep .el-card__body {
      height: 100%;
    }

    ::v-deep .el-tabs {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      // overflow: auto;
    }



    .el-tab-pane {
      height: 100%;
      // width: 100%;
      // overflow: auto;
    }

    ::v-deep .el-tabs__content {
      flex: 1;
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    ::v-deep .el-tabs__item {
      font-size: 0.8rem;

    }



    .head {
      padding: 10px 0 0 0;
      line-height: 160%;
    }

    .body {
      flex: 1;
      overflow: auto;

      .cardbody {
        font-size: 0.7rem;
        padding: 1rem 0;
      }

      .content {
        white-space: pre-wrap;
        height: calc(100%);
        background-color: #f7f6f4;
        box-sizing: border-box;
        overflow: auto;
        font-size: 0.8rem;
        padding: 1rem;
        position: relative;
        display: flex;
        flex-direction: column;

        &:hover .copy {
          display: block; // 设置父元素hover时子元素的样式 【实现要点！！！！！】
        }
      }

      .copy {
        display: none;
        position: absolute;
        top: 10px;
        right: 0;
        color: #999999;
        cursor: pointer;
      }
    }
  }
}
</style>